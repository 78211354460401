<template>
  <div class="slideshow">
    <!-- <swiper class="swiper" :options="BannswiperOption" v-if="isShow">
      <swiper-slide v-for="(item, index) in SlideList" :key="index">
        <div class="banner_img" v-swiper:swipe="{
            current: index,
            length: SlideList.length,
            swipe: 'swipe',
          }">
          <a :href="item.link" target="__blank"></a>
          <img class="shoupig" :src="item.image" v-if="item.image" alt />
          <video ref="video" id="video" autoplay="autoplay" loop class="video-s" v-if="item.video"
            :src="item.video"></video>
        </div>
      </swiper-slide>
      <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
    </swiper> -->

    <el-carousel class="swiper" trigger="click" arrow="never">
      <el-carousel-item v-for="(item,index) in SlideList" :key="index">
        <div class="banner_img" v-swiper:swipe="{
                    current: index,
                    length: SlideList.length,
                    swipe: 'swipe',
                  }">
          <a :href="item.link" target="__blank"></a>
          <img class="shoupig" :src="item.image" v-if="item.image" alt />
          <video ref="video" id="video" autoplay="autoplay" loop class="video-s" v-if="item.video"
            :src="item.video"></video>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
  import { matchType } from '@/utils/commentFun'
  import {
    Swiper,
    SwiperSlide
  } from "vue-awesome-swiper";
  export default {
    components: {
      Swiper,
      SwiperSlide
    },

    // 组件状态值
    data() {
      return {
        // isShow: false,
        SlideList: [],
        // BannswiperOption: {
        //   loop: true,
        //   speed: 1000, //每一页从开始到结束的时间
        //   autoplayDisableOnInteraction: false, //点击轮播图后是否继续自动轮播   ture否   false是
        //   initialSlide: 0,
        //   slidesPerView: 1, //显示1个
        //   centeredSlides: true, //居中
        //   observer: true, //修改swiper自己或子元素时，自动初始化swiper
        //   observeParents: true, //修改swiper的父元素时，自动初始化swiper

        //   autoplay: { // 自动滑动
        //     delay: 5000, //1秒切换一次
        //     disableOnInteraction: false
        //   },
        //   pagination: {
        //     el: ".swiper-pagination",
        //     clickable: true,
        //     type: 'bullets'
        //   },
        // }
      }
    },
    created() {

    },
    mounted() {
      this.getWebsiteCarousel()
      // setTimeout(() => {
      //   this.isShow = true;
      // }, 200);
    },
    // 组件方法
    methods: {
      getWebsiteCarousel() {
        this.$http.getWebsiteCarousel({}).then((res) => {
          if (res.status == 200) {
            this.SlideList = res.data.data.map((items) => {
              let item
              if (matchType(items.image) == 'video') {
                item = {
                  video: items.image,
                  link: items.link
                }
              } else if (matchType(items.image) == 'image')
                item = {
                  image: items.image,
                  link: items.link
                }
              return item
            })
          }
        })
      },
    },
  }
</script>

<style scoped lang='less'>
  .banner_img {
    position: relative;

    >a {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .slideshow {
    width: 100%;
    height: 100%;
    background-image: url(../../../assets/image/banner_1.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;

    video::-webkit-media-controls {
      display: none !important;
    }

    /* /deep/ .swiper-pagination-bullet-active {
      width: 46px !important;
      height: 15px !important;
      opacity: 1 !important;
      background: #ffffff !important;
      border-radius: 8px !important;
    }

    /deep/ .swiper-pagination-bullet {
      width: 15px;
      height: 15px;
      display: inline-block;
      border-radius: 100%;
      background: #ffffff;
      opacity: 0.5;
    } */

    .shoupig {
      width: 100%;
      height: 7.7rem;
      min-height: 520px;
      object-fit: cover;
    }

    .video-s {
      width: 100%;
      height: 7.7rem;
      min-height: 520px;
      object-fit: fill;
    }

    .swiper {
      height: 100%;
    }

    ::v-deep .el-carousel__container {
      height: 100%;
    }

    ::v-deep .el-carousel__button {
      width: 15px;
      height: 15px;
      display: inline-block;
      border-radius: 100%;
      background: #ffffff;
      opacity: 0.5;
    }

    ::v-deep .el-carousel__indicator.is-active {
      .el-carousel__button {
        width: 46px !important;
        height: 15px !important;
        opacity: 1 !important;
        background: #ffffff !important;
        border-radius: 8px !important;
      }
    }
  }
</style>