<template>
  <div class="container-fluid bigVessel">
    <div class="row">
      <topNavBar></topNavBar>
    </div>
    <div class="Banner row">
      <div class="left">
        <bannerCarousel></bannerCarousel>
      </div>
      <div class="right">
        <bannerTagcloud></bannerTagcloud>
      </div>
    </div>
    <div class="row">
      <about :titleSum="moduleList[0]" :configList="configList" v-if="isRouterAlive" />
    </div>
    <div class="row">
      <switchTab :titleSum="moduleList[1]" />
    </div>
    <div class="row">
      <newsletter :titleSum="moduleList[3]" />
      <masterclass :titleSum="moduleList[4]" />
      <partners :titleSum="moduleList[2]" />
      <club100></club100>
      <referendum />
      <pageFooter :type="1" />
    </div>
  </div>
</template>

<script>
  import topNavBar from "./components/topNavBar";
  import bannerCarousel from "./components/bannerCarousel";
  import bannerTagcloud from "./components/bannerTagcloud";
  import about from "./components/about/about";
  import switchTab from "./components/switchTab";
  import partners from "./components/partners";
  import club100 from "./components/club100";
  import newsletter from "./components/newsletter";
  import referendum from "./components/referendum";
  import pageFooter from "./components/pageFooter";
  import masterclass from "./components/masterclass";

  export default {
    provide() {
      return {
        reload: this.reload
      }
    },
    name: "demo",
    // 组件参数 接收父组件数据
    props: {},
    // 局部注册组件
    components: {
      topNavBar,
      bannerCarousel,
      bannerTagcloud,
      about,
      switchTab,
      partners,
      club100,
      newsletter,
      masterclass,
      referendum,
      pageFooter
    },
    // 组件状态值
    data() {
      return {
        moduleList: [],
        configList: this.$store.state.configList,
        isRouterAlive: true,
      };
    },
    // 计算属性
    computed: {},
    // 侦听器
    watch: {},
    // 组件实例创建完成，DOM未生成，
    created() {
      this.getWebsiteModuleList()
      window.onresize = function () {
        let now = window.innerWidth
        // console.log('pc',now);
        if (now < 770) {
          window.location.reload();
        }
      }
    },
    mounted() { },
    // 组件方法
    methods: {
      //模块配置
      getWebsiteModuleList() {
        this.$http.getWebsiteModuleList().then((res) => {
          if (res.status == 200) {
            this.moduleList = res.data.data
          }
        })
      },

      reload() {
        this.isRouterAlive = false
        this.$nextTick(function () {
          this.isRouterAlive = true
        })
      },
    },

  };
</script>

<style scoped lang='less'>
  .topbar {
    padding: 0;
    position: absolute;
    top: 24px;
    z-index: 9;
    opacity: 1 !important;
  }

  .bigVessel {
    width: 100%;

    .Banner {
      display: flex;
      align-items: center;
      height: 7.7rem;
      min-height: 520px;

      .left {
        width: calc(100% - 475px);
        padding: 0;
        height: 7.7rem;
        min-height: 520px;
      }

      .right {
        padding: 0;
        width: 475px;
        height: 7.7rem;
        min-height: 520px;
        display: flex;
        align-items: center;
        background-color: #0d0f30;
      }
    }
  }

  .wold {
    height: 80px;
    //line-height: 80px;
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 30px 0 30px 0;

    .woldsow {
      text-align: left;
      position: relative;
      padding: 0;

      span {
        font-size: 70px;
        font-weight: 700;
        color: rgba(240, 242, 248);
        word-break: keep-all;
        display: -webkit-box;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: pre-line;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }

      p {
        position: absolute;
        bottom: -7px;
        color: #0d0f30;
        font-size: 25px;
        font-weight: 550;
        margin-left: 50px;
      }
    }
  }

  .tabcarousel {
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>