<template>
  <!-- JCI 伙伴 -->
  <div class="partners" id="partners">
    <div class="vessel_">
      <div class="left-content col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <h1>Our Global Partners</h1>
        <el-row style="width: 1000px; margin: 0 auto">
          <el-col :span="6" v-for="item in partnerslist" :key="item.id">
            <a href="https://jcipartnerships.com/" target="__blank">
              <el-image
                style="height: 150px"
                fit="contain"
                :src="item.image"
              ></el-image>
            </a>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      partnerslist: [],
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  mounted() {
    this.getWebsitePartnerList();
  },

  methods: {
    getWebsitePartnerList(per_page, page, num) {
      this.$http
        .getWebsitePartnerList({
          per_page,
          page,
          num,
        })
        .then((res) => {
          if (res.status == 200) {
            this.partnerslist = res.data.data;
          }
        });
    },
  },
};
</script>

<style scoped lang="less">
.right-vessel::-webkit-scrollbar {
  width: 0 !important;
}
.partners {
  min-height: 900px;

  div:focus {
    outline: none;
  }
  h1 {
    font-size: 50px;
    font-weight: 700;
    text-align: center;
    color: #0d0f30;
    padding: 100px 0 60px;
  }
}
</style>
