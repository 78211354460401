<template>
  <div class="about col-lg-12 col-md-12 col-sm-12 col-xs-12" v-if="titleSum&&titleSum.is_display">
    <img :src="titleSum.image" style="
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 0.5;
        left: 0;
      " alt="" />
    <div class="wold">
      <div class="col-lg-11 col-xs-11 woldsow">
        <span :title="titleSum.description">{{titleSum.description}}</span>
        <div class="p-text">
          <p>{{ titleSum.name }}</p>
        </div>
      </div>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 contents">
      <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12 vessel-b">
        <div class="wrap">
          <div class="pic1 pic">
            <div class="btn_pic pic_num1">
              <span>Mission Vision and Values</span>
            </div>
            <div class="child" v-if="configList.our_brand">
              <div class="pic1_content">
                <h3>{{ configList.our_brand.slogan }}</h3>
                <br />
                <div class="row_con">
                  <h4>Our Mission</h4>
                  <p class="text">{{ configList.our_brand.mission }}</p>
                </div>
                <div class="row_con">
                  <h4>Our Vision</h4>
                  <p class="text">{{ configList.our_brand.vision }}</p>
                </div>
                <div class="row_con row-s">
                  <h4>Our Creed</h4>
                  <p class="text" v-html="configList.our_brand.creed"></p>
                </div>
              </div>
            </div>
          </div>
          <div class="pic2 pic">
            <div class="btn_pic pic_num2">
              <span>History</span>
            </div>
            <div class="Historychild test-1" v-if="configList.our_brand">
              <div class="history_box">
                <img :src="configList.our_brand.history_img" alt="" />
              </div>
              <div class="introduce" v-html="configList.our_brand.history">
              </div>
            </div>
          </div>
          <div class="pic3 pic">
            <div class="btn_pic pic_num3">
              <span>Global Team</span>
            </div>
            <div class="BoardandJci" v-if="configList.global_team">
              <div class="title">
                <p v-if="configList != ''">
                  {{ configList.global_team.introduction }}
                </p>
              </div>
              <div class="board-vessel" v-if="configList.global_team.bod_list.length">
                <span>Board of Directors</span>
                <div class="b-content">
                  <swiper class="swiper" :options="topSwiperOption" ref="mySwiper">
                    <swiper-slide v-for="(item, index) in configList.global_team.bod_list" :key="index">
                      <div class="board-pics" @click="boardDetail(item)">
                        <el-image :src="item.image" alt="" />
                        <div class="wold">
                          <p>{{ item.name }}</p>
                        </div>
                      </div>
                    </swiper-slide>
                  </swiper>
                  <div class="bt">
                    <div class="swiper-button-prev top_prev" slot="button-prev"></div>
                    <div class="swiper-button-next top_next" slot="button-next"></div>
                  </div>
                </div>
              </div>
              <div class="board-vessel" style="margin-top: 40px" v-if="configList.global_team.staff_list.length">
                <span>World Headquarters</span>
                <div class="b-content">
                  <swiper class="swiper" :options="botSwiperOption" ref="mySwiper">
                    <swiper-slide v-for="(item, index) in configList.global_team.staff_list" :key="index">
                      <div class="board-pics" @click="boardDetail(item)">
                        <el-image :src="item.image" alt="" />
                        <div class="wold">
                          <p>{{ item.name }}</p>
                        </div>
                      </div>
                    </swiper-slide>
                  </swiper>
                  <div class="bt">
                    <div class="swiper-button-prev bot_prev" slot="button-prev"></div>
                    <div class="swiper-button-next bot_next" slot="button-next"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pic4 pic">
            <div class="btn_pic pic_num4">
              <span>Ambassadors</span>
            </div>
            <div class="Ambassadors-s" v-if="configList.ambassadors">
              <div class="title">
                <p>
                  {{ configList.ambassadors.introduction }}
                </p>
              </div>
              <div class="Ambassadors-vessel" v-if="configList.ambassadors.list.length">
                <div class="left">
                  <div class="left-top">
                    <img :src="activeData.image" alt="" />
                    <div class="wold">
                      <!-- <p>{{ configList.ambassadors.list[0].name }}</p> -->
                      <p>{{ activeData.name }}</p>
                    </div>
                  </div>
                  <div class="left-bottom">
                    <!-- <p>{{ configList.ambassadors.list[0].introduction }}</p> -->
                    <p>{{activeData.introduction }}</p>
                  </div>
                </div>
                <div class="right">
                  <div class="elselist" v-for="(item, index) in configList.ambassadors.list" :key="index"
                    @click="setFirst(item)">
                    <img :src="item.image" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog :visible.sync="centerDialogVisible" width="40%" top="8vh" center>
      <div class="pic3_detail">
        <div class="pic3_top">
          <div class="pic3_img_box">
            <el-image :src="pic3Detail.image" alt="" />
          </div>
          <div class="pic3_name_box">
            <h3>{{ pic3Detail.name }}</h3>
            <div class="my_positions">
              <span>{{ pic3Detail.positions }}</span>
            </div>
          </div>
        </div>
        <div class="pic_bottom">
          {{ pic3Detail.introduction }}
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import {
    Swiper,
    SwiperSlide
  } from 'vue-awesome-swiper'
  export default {
    inject: ['reload'],
    components: {
      Swiper,
      SwiperSlide
    },
    // 组件状态值
    props: {
      titleSum: {
        type: Object
      },
      configList: {
        type: Object
      }
    },
    data() {
      return {
        topSwiperOption: {
          slidesPerView: 4,
          spaceBetween: 10,
          slidesPerGroup: 4,
          // initialSlide: 0,
          speed: 1000,
          // loop: true,
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          },
          autoplay: { // 自动滑动
            delay: 5000, //5秒切换一次
            disableOnInteraction: false
          },
          navigation: {
            nextEl: '.top_next',
            prevEl: ".swiper-button-prev.top_prev",
          },

        },
        botSwiperOption: {
          slidesPerView: 4,
          spaceBetween: 10,
          slidesPerGroup: 4,
          // initialSlide: 0,
          speed: 1000,
          // loop: true,

          pagination: {
            el: '.swiper-pagination',
            clickable: true
          },
          autoplay: { // 自动滑动
            delay: 5000, //5秒切换一次
            disableOnInteraction: false
          },
          navigation: {
            nextEl: '.bot_next',
            prevEl: ".swiper-button-prev.bot_prev",
          },
        },
        centerDialogVisible: false,
        pic3Detail: {},
        activeData: {}
      };
    },

    created() {
      window.addEventListener('resize', this.reload, false);
    },

    mounted() {
      this.changeItem()
      this.activeData = this.configList.ambassadors.list[0]
    },
    // 组件方法
    methods: {
      boardDetail(item) {
        this.centerDialogVisible = true
        this.pic3Detail = item;
      },

      setFirst(user) {
        // 先查找这个条数据所在位置
        // let index = this.configList.ambassadors.list.findIndex(item => item.id == user.id);
        // this.configList.ambassadors.list.splice(index, 1);
        // this.configList.ambassadors.list.unshift(user);
        // console.log('index', index)
        this.activeData = user
      },

      changeItem() {
        /*********初始设置**************/
        var imgOuter, imgDiv, btn_pic, imgOuterWidth

        //设置部分初始值与计算

        var timeId = null //记录如片轮换的定时器，自动轮换与鼠标控制切换时用到

        var edgeDistance = 1.4
        //记录相邻图片错开的距离

        var imgNow = 0 //记录当前显示的第几张图片，此处为默认值0

        var imgMouse = 0 //记录鼠标停留在第几张图片上

        //获取节点对象


        setTimeout(() => {
          imgOuter = $('.wrap') //所有图片所处的容器

          imgOuterWidth = imgOuter.width() //记录所有图片最外层容器的宽度，计算即将显示第一张图片时的，其他图片移动的距离

          imgDiv = $('.pic') //各图片所在的div容器
          btn_pic = $('.btn_pic')

          /*鼠标影响图片轮换*/
          imgDiv.hover(function () {
            //获得鼠标停留在第几张图片，调用鼠标事件的方法
            imgMouse = $(this).index()
          })

          btn_pic.bind('click', function () {
            if (imgMouse != imgNow) {
              mouseSlide()
            }
          })
        }, 500);


        // alert(imgDiv.length());

        /**********方法*************/

        /*图片自动轮换的方法主体*/
        /*鼠标影响图片轮换的方法主体*/

        function mouseSlide() {
          //判断鼠标所在图片是否已轮换过，选择右边图片移动或左边图片移动
          var vW = window.innerWidth;
          if (imgMouse > imgNow) {
            //鼠标左边图片移动，即鼠标选中的是当前图片右边的图片
            //  window.alert('2')
            for (var i = imgNow; i < imgMouse; i++) {
              //图片左上角数字样式改变
              //图片移动
              // imgDiv
              //   .eq(i)
              //   .stop()
              //   .animate({ left: -imgOuterWidth + edgeDistance * (i + 1) + 420 + 'px' }, 700)
              imgDiv
                .eq(i + 1)
                .stop()
                .animate({ left: edgeDistance * (i + 1) + 'rem' }, 700)
            }
            //重置当前图片的索引
            imgNow = imgMouse
          } else {
            //鼠标右边图片移动，即鼠标选中的是当前图片左边的图片
            for (var i = imgNow - 1; i >= imgMouse; i--) {
              //图片左上角数字样式改变
              //图片移动
              // imgDiv
              //   .eq(i)
              //   .stop()
              //   .animate(
              //     { left: edgeDistance * i + 'px' },
              //     700
              //   )
              imgDiv
                .eq(i + 1)
                .stop()
                .animate({ left: (imgOuterWidth / (vW * 100 / 1920)) - edgeDistance * (3 - i) + 'rem' }, 700)
            }

            //重置当前图片的索引
            imgNow = imgMouse
          }
        }
      }
    },

  };
</script>

<style scoped lang='less' src='./about.less'>
</style>