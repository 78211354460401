<template>
  <div class="BannerTagcloud">
    <div class="vessel" v-if="flag" style="height: 450px">
      <svg @mousemove="listener($event)" style="width: 100%; height: 100%">
        <a
          :href="tag.link"
          v-for="tag in tags"
          :key="tag.num"
          target="_blank"
          style="text-align: center; display: block"
        >
          <text
            :x="tag.x"
            :y="tag.y"
            :font-size="20 * (600 / (600 - tag.z))"
            :fill-opacity="(400 + tag.z) / 600"
            stroke-width="1"
            fill="white"
          >
            {{ tag.text }}
          </text>
          <svg :x="tag.x + 30" :y="tag.y" class="svgs">
            <circle
              cx="10"
              cy="10"
              r="5"
              style="fill: #edbe38"
              class="circles"
            />
          </svg>
        </a>
      </svg>
    </div>

    <div class="focus">
      <p>We have {{ user_count }} users on the platform</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "demo",
  // 组件参数 接收父组件数据
  props: {},
  // 局部注册组件
  components: {},
  // 组件状态值
  data() {
    return {
      width: 450, //svg宽度
      height: 450, //svg高度
      tagsNum: 4, //标签数量
      RADIUS: 150, //球的半径
      speedX: Math.PI / 360, //球一帧绕x轴旋转的角度
      speedY: Math.PI / 360, //球-帧绕y轴旋转的角度
      tags: [],
      // list: [],
      flag: false,
      user_count: 0,
      tagsList: [
        { text: "Chat", link: "https://jci.cc/web/#/chat" },
        { text: "Profile", link: "https://jci.cc/web/#/profile" },
        { text: "Meeting", link: "https://jci.cc/web/#/Meeting" },
        { text: "Channel", link: "https://jci.cc/web/#/channel-list" },
        { text: "Donation", link: "https://jci.cc/web/#/donation" },
        { text: "Event", link: "https://jci.cc/web/#/event" },
        { text: "Program", link: "https://jci.cc/web/#/award" },
        { text: "Library", link: "https://jci.cc/web/#/library" },
        { text: "JCI Senate", link: "https://jci.cc/web/#/senator" },
        { text: "Skills Development", link: "https://jci.cc/web/#/skill" },
        { text: "JCI Projects", link: "https://jci.cc/web/#/project-gallery" },
        { text: "JCI Organizations", link: "https://jci.cc/web/#/jci-organizations" },
        { text: "Club 100", link: "https://jci.cc/web/#/club" },
      ],
    };
  },
  // 计算属性
  computed: {
    CX() {
      //球心x坐标
      return this.width / 2;
    },
    CY() {
      //球心y坐标
      return this.height / 2;
    },
  },
  // 侦听器
  watch: {},
  // 组件实例创建完成，DOM未生成，
  created() {},
  mounted() {
    setInterval(() => {
      this.rotateX(this.speedX);
      this.rotateY(this.speedY);
    }, 17);
    this.getWebsiteIndexInfo();
  },
  // 组件方法
  methods: {
    getWebsiteIndexInfo() {
      this.tagsNum = this.tagsList.length;
      this.means();
      this.flag = true;
      this.$http.getWebsiteIndexInfo({}).then((res) => {
        if (res.status == 200) {
          this.user_count = res.data.data.user_count || 0;
          // this.list = res.data.data.module_list
        }
      });
    },
    means() {
      let tags = [];
      for (let i = 0; i < this.tagsNum; i++) {
        let tag = {};
        let k = -1 + (2 * (i + 1) - 1) / this.tagsNum;
        let a = Math.acos(k);
        let b = a * Math.sqrt(this.tagsNum * Math.PI); //计算标签相对于球心的角度
        tag.text = this.tagsList[i].text;
        tag.link = this.tagsList[i].link;

        tag.x = this.CX + this.RADIUS * Math.sin(a) * Math.cos(b); //根据标签角度求出标签的x,y,z坐标
        tag.y = this.CY + this.RADIUS * Math.sin(a) * Math.sin(b);
        tag.z = this.RADIUS * Math.cos(a);
        // tag.href = "https://imgss.github.io"; //给标签添加链接

        tags.push(tag);
      }
      this.tags = tags; //让vue替我们完成视图更新
    },
    rotateX(angleX) {
      var cos = Math.cos(angleX);
      var sin = Math.sin(angleX);
      for (let tag of this.tags) {
        var y1 = (tag.y - this.CY) * cos - tag.z * sin + this.CY;
        var z1 = tag.z * cos + (tag.y - this.CY) * sin;
        tag.y = y1;
        tag.z = z1;
      }
    },
    rotateY(angleY) {
      var cos = Math.cos(angleY);
      var sin = Math.sin(angleY);
      for (let tag of this.tags) {
        var x1 = (tag.x - this.CX) * cos - tag.z * sin + this.CX;
        var z1 = tag.z * cos + (tag.x - this.CX) * sin;
        tag.x = x1;
        tag.z = z1;
      }
    },
    listener(event) {
      //响应鼠标移动
      var x = event.clientX - this.CX;
      var y = event.clientY - this.CY;
      this.speedX =
        x * 0.0001 > 0
          ? Math.min(this.RADIUS * 0.00002, x * 0.0001)
          : Math.max(-this.RADIUS * 0.00002, x * 0.0001);
      this.speedY =
        y * 0.0001 > 0
          ? Math.min(this.RADIUS * 0.00002, y * 0.0001)
          : Math.max(-this.RADIUS * 0.00002, y * 0.0001);
    },
  },
  //组件 DOM 已经更新
  updated() {},
  // 实例销毁之前调用。
  beforeDestroy() {},
  // Vue 实例销毁后调用,事件监听器会被移除
  destroyed() {},
};
</script>

<style scoped lang='less'>
.BannerTagcloud {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .vessel {
    position: relative;
    left: -5%;
    margin-top: 1.5rem;

    .dot {
      width: 20px;
      height: 20px;
      background-color: yellow;
    }

    .svgs {
      position: absolute;
      right: 0;
    }
  }

  .focus {
    position: absolute;
    bottom: 0.3rem;
    font-size: 20px;
    left: 100px;
    color: #ffffff;
  }
}
</style>