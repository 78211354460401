<template>
  <div class="masterclass col-lg-12 col-md-12" v-if="titleSum&&titleSum.is_display">
    <div class="wold">
      <div class="col-lg-11 col-md-11 col-sm-11 woldsow">
        <span :title="titleSum.description">{{ titleSum.description }}</span>
        <p>{{ titleSum.name }}</p>
      </div>
    </div>

    <div class="vessel">
      <div class="desc col-lg-11 col-md-12 col-sm-12">
        <p style="margin-bottom: 18px;">{{titleSum.brief_introduction}}</p>
        <!-- Clicking on the items below will take you to the login portal for the JVC. -->
      </div>
    </div>

    <div class="vessel" style="margin: 10px 0px">
      <div class="master_image col-lg-11 col-md-12 col-sm-12">
        <img :src="titleSum.image" alt="" @click="toDetail(titleSum)">
      </div>
    </div>

  </div>
</template>

<script>

  export default {
    props: {
      titleSum: {
        type: Object
      }
    },
    data() {
      return {
      }
    },

    mounted() {
    },
    // 组件方法
    methods: {
      toDetail(titleSum) {
        window.open(titleSum.link, '_self')
      }
    },

  }
</script>

<style scoped lang='less'>
  .masterclass {
    position: relative;
    background-color: #0d0f30;
    padding: 0 0 20px;

    div:focus {
      outline: none;
    }

    .desc {
      font-size: 18px;
      text-align: left;
      color: #fff;
    }

    .wold {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 0;
      margin: .2rem 0 0px 0;
      position: relative;

      .woldsow {
        text-align: left;
        padding: 0;

        span {
          font-size: 110px;
          font-weight: 700;
          color: #ffffff;
          opacity: 0.2;
          word-break: keep-all;
          display: -webkit-box;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: pre-line;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }

        p {
          position: absolute;
          bottom: 22px;
          color: #ffffff;
          font-size: 50px;
          font-weight: 550;
          margin-left: 50px;
        }
      }
    }

    .vessel {
      display: flex;
      justify-content: center;
    }

    .master_image {
      margin: 0 auto .3rem;

      img {
        width: 100%;
        max-height: 715px;
        cursor: pointer;
      }
    }
  }
</style>