<template>
  <div class="newsletter col-lg-12">
    <div class="news-vessel">
      <div class="contents col-lg-11 col-md-11 col-sm-12">
        <img style="width: 100%" src="../../../assets/pc_img/club100.jpg" alt="" />
        <section v-if="swiperList.length">
          <h1>CLUB 100 MEMBERS</h1>
          <div class="news_box">
            <div class="swiper_box">
              <swiper class="swiper" :options="clubSwiperOption" ref="mySwiper">
                <swiper-slide v-for="(item, index) in swiperList" :key="index">
                  <a :href="item.link" style="text-decoration:none" target="_blank">
                    <div class="contents-pics">
                      <p :class="
                          item.membership_level == 1
                            ? 'color1'
                            : item.membership_level == 2
                            ? 'color2'
                            : item.membership_level == 3
                            ? 'color3'
                            : 'color4'
                        ">
                        {{
                        item.membership_level == 1
                        ? "BRONZE"
                        : item.membership_level == 2
                        ? "SILVER"
                        : item.membership_level == 3
                        ? "GOLD"
                        : "PLATINUM"
                        }}
                      </p>
                      <div class="top" :class="
                          item.membership_level == 1
                            ? 'level1'
                            : item.membership_level == 2
                            ? 'level2'
                            : item.membership_level == 3
                            ? 'level3'
                            : 'level4'
                        ">
                        <img :src="item.company_logo" alt="" :data-url="item.company_logo" :name="item.id" />
                      </div>
                    </div>
                  </a>
                </swiper-slide>
              </swiper>
            </div>

            <div>
              <div class="swiper-button-prev News_prev" slot="button-prev"></div>
              <div class="swiper-button-next News_next" slot="button-next"></div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
  import { Swiper, SwiperSlide } from "vue-awesome-swiper";
  export default {
    name: "demo",
    // 组件参数 接收父组件数据  385
    props: {
      titleSum: {
        type: Object,
      },
    },
    // 局部注册组件
    components: {
      Swiper,
      SwiperSlide,
    },
    // 组件状态值
    data() {
      return {
        isok: false,
        clubSwiperOption: {
          slidesPerView: 4,
          spaceBetween: 10,
          slidesPerGroup: 4,

          speed: 1000,
          // loop: true,

          loopFillGroupWithBlank: true,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
          autoplay: {
            // 自动滑动
            delay: 5000, //5秒切换一次
            disableOnInteraction: false,
          },
          navigation: {
            nextEl: ".News_next",
            prevEl: ".News_prev",
          },
          on: {
            // 使用es6的箭头函数，使this指向vue对象
            click: (e) => { },
          },
        },
        swiperList: [],
        totalSum: "",
        NewsInfoList: [],
      };
    },
    // 计算属性
    computed: {
      swiper() {
        return this.$refs.mySwiper.$swiper;
      },
    },

    // 侦听器
    watch: {},
    // 组件实例创建完成，DOM未生成，
    created() { },
    mounted() {
      this.officialClub();
    },
    // 组件方法
    methods: {
      showDetails(item) {
        window.open(`https://jci.cc/web/#/clubDet?id=${item.id}`, "_blank")
      },
      officialClub() {
        this.$http.officialClub({}).then((res) => {
          if (res.status === 200) {
            this.swiperList = res.data.data;
            this.totalSum = res.data.data.length;
          }
        });
      },
      //新闻详情
      getWebsiteNewsInfo(id) {
        this.$http
          .getWebsiteNewsInfo({
            id: id,
          })
          .then((res) => {
            if (res.status == 200) {
              this.NewsInfoList = res.data.data;
            }
          });
      },
    },
    //组件 DOM 已经更新
    updated() { },
    // 实例销毁之前调用。
    beforeDestroy() { },
    // Vue 实例销毁后调用,事件监听器会被移除
    destroyed() { },
  };
</script>

<style scoped lang="less">
  .col-lg-12 {
    float: left;
  }

  .newsletter {
    /* height: 14rem; */
    /* min-height: 750px; */
    padding: 0;
    flex-wrap: wrap;

    div:focus {
      outline: none;
    }

    /deep/ .swiper-button-prev,
    .swiper-button-next {
      position: absolute;
      top: 45%;
      width: calc(var(--swiper-navigation-size) / 44 * 27);
      height: var(--swiper-navigation-size);
      margin-top: calc(-1 * var(--swiper-navigation-size) / 2);
      z-index: 10;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--swiper-navigation-color, var(--swiper-theme-color));
    }

    /deep/ .swiper-button-prev {
      left: 0.2rem;
    }

    /deep/ .swiper-button-next {
      right: 0.2rem;
    }

    .news-vessel {
      display: flex;
      justify-content: center;

      .contents {
        padding: 1rem 0.5rem 0;

        h1 {
          font-size: 50px;
          font-weight: 700;
          text-align: center;
          color: #0d0f30;
          padding: 50px 0 0;
        }
      }

      .news_box {
        position: relative;
        padding: 0 0.7rem;
      }

      .contents-pics {
        margin: 0.5rem auto;

        p {
          width: 3rem;
          margin: 0 auto;
          text-align: left;
          line-height: 10px;
          font-size: 12px;
          font-weight: 600;
          background-clip: text;
          -webkit-background-clip: text;
          color: transparent;
        }

        .color1 {
          background-image: linear-gradient(150deg,
              #e77c34,
              #fac781 50%,
              #f9e8cb);
        }

        .color2 {
          background-image: linear-gradient(150deg,
              #bfbfbd,
              #f1f1f0 50%,
              #bfbfbd);

        }

        .color3 {
          background-image: linear-gradient(150deg,
              #fbe67b,
              #fdfae1 50%,
              #fbe67b);
        }

        .color4 {
          background-image: linear-gradient(120deg,
              #A5B9C7,
              #f4f7f8 50%,
              #dae4ec);
        }

        .top {
          width: 3rem;
          height: 3rem;
          margin: 0 auto;
          cursor: pointer;
          padding: 8px;

          img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .level1 {
          background-image: linear-gradient(150deg,
              #e77c34,
              #fac781 50%,
              #f9e8cb);
        }

        .level2 {
          background-image: linear-gradient(150deg,
              #bfbfbd,
              #f1f1f0 50%,
              #bfbfbd);

        }

        .level3 {
          background-image: linear-gradient(150deg,
              #fbe67b,
              #fdfae1 50%,
              #fbe67b);
        }

        .level4 {
          background-image: linear-gradient(120deg,
              #dae4ec,
              #f4f7f8 50%,
              #dae4ec);
        }
      }

      .News_prev {
        color: #0d0f30;
      }

      .News_next {
        color: #0d0f30;
      }
    }

    @keyframes fadeio {

      /*设置内容由显示变为隐藏*/
      0% {
        opacity: 1;
      }

      50% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    .fadenum {
      animation: fadeio 3s infinite;
      -webkit-animation-iteration-count: 1;
    }
  }
</style>