const matchType = (fileName) => {
  // 后缀获取
  var suffix = '';
  // 获取类型结果
  var result = '';
  try {
    var fileArr = fileName.split('.');
    suffix = fileArr[fileArr.length - 1];
  } catch (err) {
    suffix = '';
  }

  // fileName无后缀返回 false
  if (!suffix) {
    result = false;
    return result;
  }

  // 图片格式
  var imgList = ['png', 'jpg', 'jpeg', 'bmp', 'gif'];
  // 进行图片匹配
  result = imgList.some(function (item) {
    return item == suffix;
  });
  if (result) {
    result = 'image';
    return result;
  };

  // 匹配 视频
  var videoList = ['mp4', 'm2v', 'mkv'];
  result = videoList.some(function (item) {
    return item == suffix;
  });
  if (result) {
    result = 'video';
    return result;
  };

  // 匹配 音频
  var radioList = ['mp3', 'wav', 'wmv'];
  result = radioList.some(function (item) {
    return item == suffix;
  });
  if (result) {
    result = 'radio';
    return result;
  }
}


export {
  matchType
}