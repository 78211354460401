<template>
  <div class="referendum_content col-xs-12" v-if="referList.length">
    <div v-for="(item, ind) of referList" :key="ind" :id="`publicvoting_${item.referendum_id}`">
      <div class="wold">
        <div class="col-sm-11 woldsow">
          <div style="height: 85px;">
            <span class="section_name" :title="item.section_name">{{item.section_name}}</span>
          </div>
          <p class="col-lg-11 col-md-11 col-sm-12" style="color:#0D0F30;">{{item.title}}</p>
          <div class="col-lg-11 col-md-11 col-sm-12" style="padding: 20px auto;font-size: 18px;margin-bottom: 20px;"
            v-html="item.introduction">
          </div>
        </div>
      </div>
      <div class="newsVessel">
        <div class="contents col-lg-11 col-md-11 col-sm-12">
          <div class="new-content">
            <div class="new-main">
              <div style="text-align: left;padding-left: 0.65rem;">
                <el-image style="width: 180px;height: 60px;" :src="item.cover" cover></el-image>
              </div>
              <section style="position:relative" v-if="item.users.length">
                <swiper :options="item.newsSwiperOption" class="swiper-content">
                  <swiper-slide v-for="(v, i) in item.users" :key="i">
                    <div class="contents-pics">
                      <div class="top" @click="checkIntro(v)">
                        <el-image :src="v.head_portrait" alt="" :data-url="v.head_portrait"
                          :name="v.referendum_user_id">
                        </el-image>
                      </div>
                      <div class="bom">
                        <div class="title" @click="checkIntro(v)" :title="v.user_name">{{v.user_name}}
                        </div>
                        <div style="text-align: right;padding:0" v-if="item.type">
                          <el-button :disabled="v.is_vote=='1'" @click="checkReferendum(v,item)" size="large" round
                            style="color: #fff" :style="v.is_vote=='1' ? 'background:#6e6ead' : 'background:#0d0f30'">
                            {{v.is_vote?'Voted':'Vote'}}
                          </el-button>
                        </div>
                      </div>
                    </div>
                  </swiper-slide>
                </swiper>
                <div class="btnGroup">
                  <div class="swiper-button-prev News_prev" :class="`News_prev_${ind}`" slot="button-prev"></div>
                  <div class="swiper-button-next News_next" :class="`News_next_${ind}`" slot="button-next"></div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 详情弹框 -->
    <el-dialog :visible.sync="show" closeable width="40%" top="8vh" center>
      <div class="user_vote_box" ref="scrollRef">
        <div class="pic3_img_box">
          <el-image :src="introDet.head_portrait" class="img" />
          <div class="pic3_name_box">
            <h3>{{ introDet.user_name }}</h3>
            <div class="news_dialog" v-html="introDet.introduction"></div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
  export default {
    data() {
      return {
        show: false,
        introDet: {},
        referList: [],

        random: "",
        flag: true
      };
    },
    props: {
    },

    components: {
      Swiper,
      SwiperSlide
    },

    mounted() {
      this.getIP();
      this.getReferendum()
    },

    methods: {
      getIP() {
        this.random = localStorage.getItem("random");
        if (!this.random) {
          this.random = Math.random();
          if (this.random == '0') this.random = Math.random();
          localStorage.setItem("random", this.random)
        }
      },

      checkReferendum(v, item) {
        this.$http.checkReferendum({
          participant_ip: this.random,
          referendum_user_id: v.referendum_user_id,
        }).then(res => {
          if (res.data.status == 200) {
          } else {
            this.$message.warning(res.data.message)
          }
          this.getReferendum();
        })
      },

      checkIntro(intro) {
        this.introDet = intro;
        this.show = true;
        // 回到顶部
        this.$nextTick(() => {
          this.$refs.scrollRef.scrollTop = 0;
        })
      },

      getReferendum() {
        this.$http.getReferendum({
          participant_ip: this.random
        }).then((res) => {
          if (res.status == 200) {
            this.referList = res.data.data;
            this.referList.forEach((element, i) => {
              let newsSwiperOption = {
                loop: false,
                navigation: {
                  nextEl: `.News_next_${i}`,
                  prevEl: `.News_prev_${i}`
                },
                autoplay: { // 自动滑动
                  delay: 5000, //5秒切换一次
                  disableOnInteraction: false
                },
                slidesPerView: 4,
                slidesPerColumn: 2,
                spaceBetween: 30,
              }
              this.$set(element, 'newsSwiperOption', newsSwiperOption)
            });

            let referendum_id = this.$route.query.publicvoting
            if (referendum_id && this.flag) {
              this.flag = false
              setTimeout(() => {
                // console.log(document.getElementById(referendum_id));
                document.getElementById(referendum_id).scrollIntoView({
                  behavior: "auto"
                });
              }, 0);
            }

          }
        })
      }

    }
  }

</script>
<style lang="scss" scoped>
  ::v-deep .swiper-wrapper .swiper-slide {
    margin: 0 !important;
  }

  .referendum_content {
    width: 100%;
    overflow: hidden;
    margin-bottom: 1rem;
    position: relative;

    .section_name {
      opacity: 0.2 !important;
      color: #4266B0 !important;
      word-break: keep-all;
      display: -webkit-box;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: pre-line;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      position: absolute;
      left: -26px;
    }

    .wold {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 0;
      margin: .2rem 0 0px 0;
      position: relative;

      .woldsow {
        text-align: left;
        padding: 0;

        span {
          font-size: 110px;
          font-weight: 700;
          color: #ffffff;
          opacity: 0.2;
        }

        p {
          color: #ffffff;
          font-size: 50px;
          font-weight: 550;
          line-height: 62px;
        }
      }
    }

    ::v-deep ul,
    ::v-deep ol {
      padding-left: .35rem !important;
    }

    ::v-deep ul li {
      list-style: disc;
    }

    ::v-deep ol li {
      list-style: decimal;
    }

    ::v-deep li {
      white-space: inherit !important;
    }

    .new-title {
      position: relative;
      white-space: nowrap;

      >h2 {
        font-size: 1.1rem;
        font-weight: 600;
        text-align: left;
        color: rgba(255, 255, 255, .16);
        opacity: 0.2;

      }

      >span {
        font-size: 0.5rem;
        color: #0D0F30;
        text-align: left;
        opacity: 1;
        position: absolute;
        top: 50%;
        left: 4%;
      }
    }

    .newsVessel {
      display: flex;
      justify-content: center;
    }

    .new-content {
      position: relative;

      .new-main {
        padding: 0.3rem;
        background: #0d0f30;

        .swiper-content {
          width: 94%;
          height: 10rem;
          margin-left: auto;
          margin-right: auto;

          .swiper-slide {
            height: 5rem;
            margin: 0;
            padding: 0;
          }

          .contents-pics {
            margin: 0.2rem auto 1rem;

            .top {
              width: 3rem;
              height: 3rem;
              margin: 0 auto;
              padding: 0;
              cursor: pointer;
              background-color: #f4f2f2;

              img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            .bom {
              width: 3rem;
              height: 1.2rem;
              margin: 0 auto;
              background-color: #ffffff;
              padding: 15px;

              .title {
                padding: 0 5px;
                text-align: left;
                display: -webkit-box;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: pre-line;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                font-size: 18px;
                cursor: pointer;
                margin-bottom: .2rem;
              }

              button.el-button.el-button--default.el-button--large.is-disabled.is-round {
                background-color: #999 !important;
              }

              .el-button.is-round {
                border-radius: .2rem;
                padding: .12rem .23rem;
              }
            }

          }
        }

        .btnGroup {

          .News_prev {
            color: #fff;
            top: 48.5%;
          }

          .News_next {
            color: #fff;
            top: 48.5%;
          }
        }
      }
    }

    .news_dialog {
      text-align: left;
      word-spacing: normal;
      word-wrap: normal;
      word-break: normal;
      line-height: 30px !important;
    }

  }

  .user_vote_box {
    margin-bottom: 20px;
    padding: 0 .6rem;
    max-height: 6.8rem;
    overflow: auto;

    &::-webkit-scrollbar-thumb {
      background-color: #ddd !important;
    }

    &::-webkit-scrollbar-track-piece {
      background-color: #fff;
    }

    .pic3_img_box {

      .img {
        float: left;
        width: 1.6rem;
        height: 1.6rem;
        margin-right: 0.2rem;
        display: block;
        object-fit: cover;
      }
    }

    .pic3_name_box {
      text-align: justify;

      >h3 {
        font-size: 18px;
        color: #000000;
        padding-bottom: 0.2rem;
      }

      >span {
        font-size: 14px;
        color: #ccc;
      }
    }
  }
</style>