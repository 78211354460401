<template>
  <div class="newsletter col-lg-12" v-if="titleSum&&titleSum.is_display&&swiperList.length">
    <img :src="titleSum.image" class="picture" alt="" />
    <div class="wold">
      <div class="col-lg-11 col-xs-11 woldsow">
        <span style="color: #fff; opacity: 0.2" :title="titleSum.description">{{titleSum.description}}</span>
        <p>{{ titleSum.name }}</p>
      </div>
    </div>
    <div class="news-vessel">
      <div class="contents col-lg-11 col-md-11 col-sm-12">
        <div class="news_box">
          <div class="swiper_box">
            <swiper class="swiper" :options="NewsswiperOption" ref="mySwiper">
              <swiper-slide v-for="(item, index) in swiperList" :key="index" @click="showDetails(index)">
                <div class="contents-pics" :class="{ fadenum: isok === true }">
                  <div class="top">
                    <img :src="item.cover" alt="" :data-url="item.cover" :name="item.id" />
                  </div>
                  <div class="fol">
                    <div class="title">
                      <p>{{ item.title }}</p>
                    </div>
                    <div class="titme">
                      <span>{{ item.created_at }}</span>
                    </div>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
          </div>

          <div>
            <div class="swiper-button-prev News_prev" slot="button-prev"></div>
            <div class="swiper-button-next News_next" slot="button-next"></div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="slot" :visible.sync="centerDialogVisible" width="40%" top="8vh" center>
      <template slot="title" v-if="NewsInfoList">
        <div class="title-dialog">
          <p>{{ NewsInfoList.title }}</p>
          <br />
          <span>{{ NewsInfoList.created_at }}</span>
        </div>
      </template>
      <div v-if="NewsInfoList" class="news_info_detail">
        <p v-html="NewsInfoList.introduction"></p>
      </div>
    </el-dialog>
  </div>
</template>


<script>
  import {
    Swiper,
    SwiperSlide
  } from 'vue-awesome-swiper'
  export default {
    name: 'demo',
    // 组件参数 接收父组件数据  385
    props: {
      titleSum: {
        type: Object
      }
    },
    // 局部注册组件
    components: {
      Swiper,
      SwiperSlide
    },
    // 组件状态值
    data() {
      return {
        isok: false,
        NewsswiperOption: {
          slidesPerView: 4,
          spaceBetween: 10,
          slidesPerGroup: 4,

          speed: 1000,
          // loop: true,

          loopFillGroupWithBlank: true,
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          },
          autoplay: { // 自动滑动
            delay: 5000, //5秒切换一次
            disableOnInteraction: false
          },
          navigation: {
            nextEl: '.News_next',
            prevEl: '.News_prev'
          },
          on: {
            // 使用es6的箭头函数，使this指向vue对象
            click: (e) => {
              const id = e.target.name
              if (id) {
                this.centerDialogVisible = true
                this.getWebsiteNewsInfo(id)
              }
            },
            slideChangeTransitionStart: () => {
              let swiper = this.$refs.mySwiper.$swiper;
              if (swiper) {
                this.isok = true
              }
            },
            slideChangeTransitionEnd: () => {
              let swiper = this.$refs.mySwiper.$swiper;
              if (swiper) {
                this.isok = false
              }
            },
          },
        },
        swiperList: [],
        totalSum: "",
        centerDialogVisible: false,
        NewsInfoList: []
      }
    },
    // 计算属性
    computed: {
      swiper() {
        return this.$refs.mySwiper.$swiper;
      }
    },

    // 侦听器
    watch: {

    },
    // 组件实例创建完成，DOM未生成，
    created() {


    },
    mounted() {
      this.getWebsiteNewsList()
    },
    // 组件方法
    methods: {
      getWebsiteNewsList(is_recommend) {
        this.$http.getWebsiteNewsList({
          per_page: 999,
          is_recommend: 0
        }).then((res) => {
          if (res.status === 200) {

            this.swiperList = res.data.data.list
            this.totalSum = res.data.data.total
          }
        })

      },
      //新闻详情
      getWebsiteNewsInfo(id) {
        this.$http.getWebsiteNewsInfo({
          id: id
        }).then((res) => {
          if (res.status == 200) {
            this.NewsInfoList = res.data.data
          }
        })
      }
    },
    //组件 DOM 已经更新
    updated() { },
    // 实例销毁之前调用。
    beforeDestroy() { },
    // Vue 实例销毁后调用,事件监听器会被移除
    destroyed() { }
  }
</script>

<style scoped lang='less'>
  .newsletter {
    height: 9.4rem;
    min-height: 750px;
    background-color: rgba(43, 49, 54);
    padding: 0;
    flex-wrap: wrap;

    .news_info_detail {
      ::v-deep p {
        line-height: 24px !important;
        margin-top: 5px !important;
      }
    }

    ::v-deep ul,
    ::v-deep ol {
      padding-left: 40px !important;
    }

    /deep/ ul li {
      list-style-type: disc;
    }

    /deep/ ol li {
      list-style-type: decimal;
    }

    /deep/ li {
      white-space: inherit !important;
      margin-top: 5px;
      line-height: 24px !important;
    }

    /deep/.el-dialog__wrapper {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow: auto;
      margin: 0;
      background-color: #808088;
    }

    /deep/ .el-dialog--center .el-dialog__body {
      text-align: initial;
      padding: 25px 25px 30px;
      min-height: 7rem;
      max-height: 7rem;
      overflow-y: auto;
    }

    .picture {
      object-fit: cover;
      width: 100%;
      height: 100%;
      position: absolute;
      opacity: 0.5;
      left: 0;
    }

    div:focus {
      outline: none;
    }

    /deep/ .swiper-button-prev,
    .swiper-button-next {
      position: absolute;
      top: 45%;
      width: calc(var(--swiper-navigation-size) / 44 * 27);
      height: var(--swiper-navigation-size);
      margin-top: calc(-1 * var(--swiper-navigation-size) / 2);
      z-index: 10;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--swiper-navigation-color, var(--swiper-theme-color));
    }

    /deep/ .swiper-button-prev {
      left: .2rem;
    }

    /deep/ .swiper-button-next {
      right: .2rem;
    }

    .wold {
      height: 144px;
      padding: 0;
      text-align: left;
      display: flex;
      justify-content: center;

      span {
        font-size: 110px;
        font-weight: 700;
        color: #ffffff;
        opacity: 0.1;
        word-break: keep-all;
        display: -webkit-box;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: pre-line;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }

      p {
        position: absolute;
        top: 76px;
        color: #ffffff;
        font-size: 50px;
        font-weight: 550;
        margin-left: 50px;
      }
    }

    .news-vessel {
      display: flex;
      justify-content: center;

      .contents {
        padding: .5rem .58rem;
      }

      .news_box {
        position: relative;
        background: #0d0f30;
        padding: 0 .7rem;
      }

      .contents-pics {
        margin: 1.2rem auto;

        .top {
          width: 3rem;
          height: 3rem;
          margin: 0 auto;
          cursor: pointer;

          img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .fol {
          padding-top: 10px;
          height: 85px;
          color: #ffffff;
          line-height: 26px;
          text-align: center;

          .title {
            height: 45px;
            display: -webkit-box;
            /* word-break: break-all; */
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: pre-line;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            font-size: 18px;
          }

          .time {
            color: #ffffff;
            opacity: 0.1;
            font-size: 12px;
          }
        }
      }

      .News_prev {
        color: #ffffff;
      }

      .News_next {
        color: #ffffff;
      }
    }

    .title-dialog {
      text-align: left;

      p {
        font-weight: 700;
        font-size: 20px;
      }
    }

    /deep/.el-dialog__body {
      word-break: inherit;

      img {
        width: 100%;
      }
    }

    @keyframes fadeio {

      /*设置内容由显示变为隐藏*/
      0% {
        opacity: 1;
      }

      50% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    .fadenum {
      animation: fadeio 3s infinite;
      -webkit-animation-iteration-count: 1;
    }
  }
</style>