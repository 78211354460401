<template>
  <div class="switchTab col-lg-12 col-md-12" v-if="titleSum&&titleSum.is_display">
    <img :src="titleSum.image" style="
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 0.5;
        left: 0;
      " alt="" />
    <!-- 标题 -->
    <div class="wold">
      <div class="col-lg-11 col-md-11 col-sm-11 woldsow">
        <span :title="titleSum.description">{{ titleSum.description }}</span>
        <p>{{ titleSum.name }}</p>
      </div>
    </div>

    <div class="vessel">
      <div class="desc col-lg-11 col-md-12 col-sm-12">
        <p style="margin-bottom: 18px;">{{descData[RowIndex]}}</p>
        Clicking on the items below will take you to the login portal for the JVC.
      </div>
    </div>
    <!-- menu 切换 -->
    <div class="vessel">
      <div class="card col-lg-11 col-md-12 col-sm-12">
        <div class="cut-row col-lg-3 col-md-3">
          <ul>
            <li v-for="(item, index) in listRow" :key="index" class="animated leftMenu" :class="{
                'list slideInLeft ': RowIndex == item.type,
              }" @click="lichilck(item.type)">
              <div style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  padding-right: 15px;
                ">
                <span style="margin-left: 20px">{{ item.model }} </span>
                <i class="ri-arrow-right-line" v-if="RowIndex == item.type"></i>
              </div>
            </li>
          </ul>
        </div>
        <div class="swiper-pics col-lg-9 col-md-9">
          <swiper class="swiper" :options="swiperOption" ref="mySwiper">
            <swiper-slide v-for="(item, index) in list" :key="index">
              <div class="contents-pics animated slideInRight" @click="toDetail(item)">
                <div class="top">
                  <el-image :src="item.image" cover></el-image>
                </div>
                <div class="fol">
                  <div class="title" :title="item.title">
                    <span>{{ item.title }}</span>
                  </div>
                  <div class="time">
                    <span>{{ item.time }}</span>
                  </div>
                  <!-- 小头像 -->
                  <div class="group-member">
                    <div style="display: flex;">
                      <div class="iq-media-group">
                        <a v-for="(v, index) in item.avatar" :key="index" href="#" class="iq-media">
                          <img class="img-fluid rounded-circle mr-2" :src="v.avatar" alt="" />
                        </a>
                      </div>
                      <div class="col-9 w-100 pl-0 desc text-overflow-single">
                        {{ item.desc }}
                      </div>
                    </div>
                  </div>
                  <!-- <div class="text" v-html="item.introduction"></div> -->
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <div class="switch_tab_btn" v-show="list.length">
            <!-- <div class="swiper-button-prev switch_tab" slot="button-prev"></div> -->
            <div class="swiper-button-next switch_tab" slot="button-next"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    Swiper,
    SwiperSlide
  } from 'vue-awesome-swiper'

  export default {

    components: {
      Swiper,
      SwiperSlide
    },
    props: {
      titleSum: {
        type: Object
      }
    },
    data() {
      return {
        isok: false,
        listRow: [
          { model: "Events", type: 2 },
          { model: "Meetings", type: 4 },
          { model: "Projects", type: 1 },
          { model: "Skills Development", type: 5 },
        ],
        descData: {
          2: 'JCI hosts four conferences in each region and one world congress, held in a different location every year. These international events are the perfect opportunity to learn from global leaders and connect with individuals across industries. Our events also provide the backdrop to several exciting competitions in public speaking, debate and business development. Join us for a truly exciting experience with evening programs, cultural experiences and plenty of opportunities to grow as a leader, professional and person.',
          4: 'Collaboration and community are key for our organization and our meetings help facilitate that goal. Our National Presidents gather each year to define their plans of action and work together toward our common vision. Our international Board of Directors create policies and make visits to Local and National Organizations during their year of service. JCI meetings are held at every level and are a great way to learn procedure and etiquette.',
          1: 'One of ways JCI members stand out is through completion of strategic projects. This is where we put our words into action, solving problems and partnering with businesses, governments and civil society to stimulate economic growth and develop innovation solutions. A JCI project is focused around providing benefit to both our members and society at large.',
          3: 'A well-roundedleader is constantly evolving. Through events, programs, trainings and projectsJCI provides all the tools necessary for any young entrepreneur to succeed. Ourtrainers come from more than 100 countries and bring a trove of experience tothe table. With topics such as financial management, funding small business andhow to become a better communicator, JCI offers something for anyone looking togain skills and knowledge.'
        },
        RowIndex: 2,
        swiperOption: {
          slidesPerView: 4,
          spaceBetween: 20,
          slidesPerGroup: 4,
          loop: false,
          loopFillGroupWithBlank: true,
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          },
          // autoplay: { // 自动滑动
          //   delay: 5000, //5秒切换一次
          //   disableOnInteraction: false
          // },
          navigation: {
            prevEl: '.swiper-button-prev.switch_tab',
            nextEl: '.swiper-button-next.switch_tab',
          },
          on: {
            // 使用es6的箭头函数，使this指向vue对象
            slideChangeTransitionStart: () => {
              let swiper = this.$refs.mySwiper.$swiper;
              if (swiper) {
                this.isok = true
              }
            },
            slideChangeTransitionEnd: () => {
              let swiper = this.$refs.mySwiper.$swiper;
              if (swiper) {
                this.isok = false
              }
            },
          }
        },

        list: [],
        totalsum: '',
      }
    },

    computed: {
      swiper() {
        return this.$refs.mySwiper.$swiper;
      }
    },

    mounted() {
      this.getWebsiteProjectList(2)
    },
    // 组件方法
    methods: {
      toDetail(item) {
        window.open(item.herf)
      },

      lichilck(type) {
        this.list = []
        this.RowIndex = type
        this.getWebsiteProjectList(type)
      },

      //获取官网项目列表
      getWebsiteProjectList(type) {
        this.$http.getWebsiteProjectList({
          type
        }).then((res) => {
          if (res.status == 200) {
            if(type==5) return this.list = res.data.data.list.data
            this.list = res.data.data.list
          }
        })
      }
    },

  }
</script>

<style scoped lang='less'>
  .switchTab {
    position: relative;
    height: 7.8rem;
    background-color: rgb(165, 164, 164);
    padding: 0;
    min-height: 680px;

    div:focus {
      outline: none;
    }

    /* 单行文本溢出 */
    .text-overflow-single {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .group-member {
      margin-top: 10px;

      img {
        width: 24px;
        height: 24px;
      }

      .desc {
        font-size: 12px;
        color: #999999;
        line-height: 26px;
        text-align: left;
      }

      .rounded-circle {
        border-radius: 50% !important;
      }

      .iq-media-group {
        min-width: 55px;
      }

      .iq-media-group .iq-media {
        margin-left: -10px;
        position: relative;
        z-index: 0;
        transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
      }

      .iq-media-group .iq-media:hover {
        z-index: 9;
      }

      .iq-media-group .iq-media img,
      .iq-media-group .iq-media.border {
        border: 2px solid var(--iq-border-light);
      }

      .iq-media-group .iq-media:first-child {
        margin-left: 0;
      }

      .iq-media {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
      }

      .iq-media:hover {
        text-decoration: none;
      }
    }

    .desc {
      font-size: 18px;
      text-align: left;
      color: #fff;
    }

    /deep/ .swiper-button-prev,
    .swiper-button-next {
      width: calc(var(--swiper-navigation-size) / 44 * 27);
      height: var(--swiper-navigation-size);
      margin-top: calc(-1 * var(--swiper-navigation-size) / 2);
      z-index: 10;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--swiper-navigation-color, var(--swiper-theme-color));
    }

    .wold {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 0;
      margin: .3rem 0 0px 0;
      position: relative;

      .woldsow {
        text-align: left;
        padding: 0;

        span {
          font-size: 110px;
          font-weight: 700;
          color: #ffffff;
          opacity: 0.2;
          word-break: keep-all;
          display: -webkit-box;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: pre-line;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }

        p {
          position: absolute;
          bottom: 16px;
          color: #ffffff;
          font-size: 50px;
          font-weight: 550;
          margin-left: 50px;
        }
      }
    }

    //
    .vessel {
      display: flex;
      justify-content: center;

      .card {
        margin: 0 auto .3rem;
        height: 3.8rem;
        display: flex;
        justify-content: center;
        align-items: center;

        .cut-row {
          overflow: hidden;
          padding: 0;
          margin: 0;
          position: relative;
          left: 0;
          /* top: -0.7rem; */

          li {
            width: 3.2rem;
            height: 0.6rem;
            line-height: 0.6rem;
            opacity: 1;
            color: #ffffff;
            cursor: pointer;
            text-align: left;
            font-size: 0.28rem;
            overflow: hidden;
          }

          .list {
            width: 3.2rem;
            height: 0.6rem;
            line-height: 0.6rem;
            opacity: 1;
            background: #ffffff;
            color: black;
            font-size: 0.28rem;
            text-align: left;
          }
        }

        .swiper-pics {
          position: relative;

          .contents-pics {
            width: 100%;
            cursor: pointer;

            .top {
              height: 1.3rem;
              background-color: #fff;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            .fol {
              height: 1.3rem;
              min-height: 120px;
              background-color: #ffffff;
              padding: .2rem .2rem .3rem .2rem;
              text-align: left;

              overflow: hidden;

              .title {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-bottom: 10px;
                font-size: 16px;
              }

              .time {
                font-size: 14px;
              }

              .text {
                overflow: hidden;
                text-overflow: ellipsis;
                /* word-break: break-all; */
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 5;

                /deep/ * {
                  font-size: 12px !important;
                }
              }
            }
          }

          .switch_tab_btn {
            width: 100%;
            position: absolute;
            bottom: -50px;
            right: 0;

            .switch_tab {
              color: #fff;
            }
          }
        }
      }
    }
  }


  // .v-enter,
  // .v-leave-to {
  //   opacity: 0;
  //   translate: translateX(-20px);
  // }
  // .v-enter-actvie,
  // .v-leave-active {
  //   translate: all 0.8s ease;
  // }
  // .my-enter,
  // .my-leave-to {
  //   opacity: 1;
  //   translate: translateX(-20px);
  // }
  // .my-enter-actvie,
  // .my-leave-active {
  //   translate: all 0.8s ease;
  // }

  @media only screen and (max-width: 1300px) {
    .cut-row {
      li {
        width: 3.8rem !important;
      }

      .list {
        width: 3.8rem !important;
      }
    }
  }

  @media only screen and (max-width: 1000px) {
    .cut-row {
      width: 30%;
    }

    .swiper-pics {
      width: 70%;
    }
  }
</style>